import menu, { closeMenu, openMenu, openSubMenu } from "./components/menu";

import Fsbox from "fslightbox";
document.addEventListener("DOMContentLoad", () => {
  const lightbox = new Fsbox();
  lightbox.props.sources = ["first-lightbox", "example-video.mp4"];
  lightbox["first-lightbox"].props.maxImageDimensions = {
    width: "100%",
    height: "auto",
  };
  lightbox.props.onInit = () => console.log("Lightbox initialized!");
});

menu.button.open.addEventListener("click", openMenu);
menu.button.close.addEventListener("click", closeMenu);
menu.overlay.addEventListener("click", closeMenu);
menu.dropDowns.forEach((dropDown) => {
  dropDown.addEventListener("click", openSubMenu);
});
